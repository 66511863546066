/* TestCaseSteps.css */

/* Test Step Styles */
.test-steps-container {
  margin: 20px;
  position: relative;
}

.test-steps-flow {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
}

.test-step-box {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  width: 250px;
  height: 170px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 0;
  box-sizing: border-box;
  cursor: grab;
  transition: transform 0.2s, box-shadow 0.2s;
}

.test-step-box.dragging {
  opacity: 0.5;
  cursor: grabbing;
}

.test-step-box.drag-over {
  transform: scale(1.02);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.test-step-drag-handle {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #999;
  cursor: grab;
}

.test-step-drag-handle:hover {
  color: #666;
}

.test-step-box:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 50%;
  right: -20px;
  width: 20px;
  height: 1px;
  background-color: #e0e0e0;
}

.test-step-description {
  font-size: 16px;
  color: #666;
  margin-bottom: 12px;
  line-height: 1.4;
  flex-grow: 1;
  overflow-y: auto;
}

.test-step-footer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: auto;
  padding: 0 8px;
}

.action-select,
.action-input {
  width: calc(100% - 2px); /* Account for borders */
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 13px;
  background-color: white;
  height: 36px;
  box-sizing: border-box;
}

.action-select {
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 16px;
  padding-right: 32px;
}

.action-input::placeholder {
  color: #999;
}

.action-input:focus,
.action-select:focus {
  outline: none;
  border-color: #4CAF50;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
}

.test-steps-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.test-steps-actions {
  display: flex;
  gap: 10px;
}

.generate-steps-button,
.run-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  transition: background-color 0.2s;
}

.generate-steps-button {
  background-color: #9c27b0;
  color: white;
}

.run-button {
  background-color: #4CAF50;
  color: white;
}

.run-button.disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 0.7;
}

.test-case-header {
  margin-bottom: 24px;
}

.test-case-header h2 {
  margin: 0 0 8px 0;
  font-size: 24px;
}

.last-updated {
  color: #666;
  font-size: 12px;
  margin-top: 4px;
}

/* Test Results Table */
.test-results-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.test-results-table th,
.test-results-table td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
}

.test-results-table th {
  background-color: #f0f0f0;
}

.test-results-table .passed {
  color: #4CAF50;
  font-weight: bold;
}

.test-results-table .failed {
  color: #f44336;
  font-weight: bold;
}

/* Test Runs Section */
.test-runs-list {
  margin-top: 20px;
}

.test-run-item {
  padding: 15px;
  background-color: #f5f5f5;
  border-left: 5px solid;
  border-radius: 5px;
  margin-bottom: 15px;
  cursor: pointer;
}

.test-run-item.passed {
  border-left-color: #4CAF50; /* Green */
}

.test-run-item.failed {
  border-left-color: #f44336; /* Red */
}

.test-run-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.test-run-id {
  font-weight: bold;
}

.test-run-result {
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 3px;
  color: #fff;
}

.test-run-result.passed {
  background-color: #4CAF50; /* Green */
}

.test-run-result.failed {
  background-color: #f44336; /* Red */
}

.toggle-details-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
}

.test-run-details {
  margin-top: 10px;
}

.test-run-output,
.test-run-error {
  margin-top: 10px;
}

.test-run-output pre,
.test-run-error pre {
  background-color: #e0e0e0;
  padding: 10px;
  border-radius: 3px;
  overflow-x: auto;
}

.test-run-output strong,
.test-run-error strong {
  display: block;
  margin-bottom: 5px;
}

/* Test Case Details Section */
.test-case-details {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.test-case-details .test-name {
  color: #333;
  margin: 0 0 10px 0;
  font-size: 1.5em;
}

.test-case-details .test-description {
  color: #666;
  margin: 0 0 10px 0;
  line-height: 1.4;
}

.test-case-details .updated-at {
  color: #888;
  font-size: 0.9em;
  margin: 0;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .test-steps-flow {
    flex-direction: column;
    align-items: center;
  }
  
  .test-step-box {
    flex: 1 1 100%;
    max-width: none;
    margin: 8px 0;
  }
  
  .test-step-box:not(:last-child)::after {
    content: '↓';
    right: 50%;
    top: auto;
    bottom: -20px;
    transform: translateX(50%);
  }
}
