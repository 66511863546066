.clients-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.clients-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.new-client-btn {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.new-client-btn:hover {
  background-color: #45a049;
}

.clients-list table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.clients-list th,
.clients-list td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.clients-list th {
  background-color: #f5f5f5;
  font-weight: bold;
}

.clients-list tr:hover {
  background-color: #f5f5f5;
}

.error {
  color: red;
  text-align: center;
  margin-top: 20px;
}

.delete-btn {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease;
}

.delete-btn:hover:not(:disabled) {
  background-color: #c0392b;
}

.delete-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
