.new-client-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.new-client-container h1 {
  margin-bottom: 20px;
  color: #2c3e50;
  text-align: center;
}

.new-client-container form {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #2c3e50;
}

.form-group input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s;
}

.form-group input:focus {
  outline: none;
  border-color: #3498db;
}

.form-group input:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.form-actions {
  margin-top: 24px;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 12px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.submit-btn,
.cancel-btn {
  width: 140px;
  height: 40px;
  padding: 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}

.submit-btn {
  background-color: #3498db;
  color: white;
}

.submit-btn:hover:not(:disabled) {
  background-color: #2980b9;
}

.cancel-btn {
  background-color: #e74c3c;
  color: white;
}

.cancel-btn:hover:not(:disabled) {
  background-color: #c0392b;
}

.submit-btn:disabled,
.cancel-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.error {
  width: 100%;
  max-width: 400px;
  margin: 0 auto 20px;
  color: #e74c3c;
  padding: 10px;
  background-color: #fde8e7;
  border-radius: 4px;
  font-size: 14px;
}
