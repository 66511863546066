.dashboard-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  padding: 20px;
  gap: 20px;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.dashboard-header h1 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.generate-from-file-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.generate-from-file-button:hover {
  background-color: #45a049;
}

.dashboard-content {
  display: flex;
  flex: 1;
  gap: 20px;
  min-height: 0; /* Important for proper scrolling */
}

.tree-container {
  flex: 0 0 300px;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
}

.content-container {
  flex: 1;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
}
