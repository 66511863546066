.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
}

.login-box {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.login-form {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 2rem;
}

.login-form h2 {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 2rem;
  background: linear-gradient(90deg, #E21E9B 0%, #8F41E9 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.error-message {
  margin-bottom: 1rem;
  padding: 0.75rem;
  border-radius: 0.375rem;
  background-color: #FEE2E2;
  color: #DC2626;
  font-size: 0.875rem;
}

.form-group {
  margin-bottom: 1.25rem;
  position: relative;
}

.form-group label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #6B7280;
  margin-bottom: 0.5rem;
}

.form-group input {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 2px solid #E5E7EB;
  border-radius: 0.5rem;
  font-size: 1rem;
  background-color: white;
  transition: all 0.2s ease;
}

.form-group input:hover {
  border-color: #D1D5DB;
}

.form-group input:focus {
  outline: none;
  border-color: #8F41E9;
  box-shadow: 0 0 0 4px rgba(143, 65, 233, 0.1);
}

button[type="submit"] {
  width: 100%;
  padding: 0.875rem;
  margin-top: 1rem;
  background: linear-gradient(90deg, #E21E9B 0%, #8F41E9 100%);
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
  transition: opacity 0.2s;
}

button[type="submit"]:hover {
  opacity: 0.9;
}

/* Modal specific styles */
.modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
}

.modal-content {
  background: white;
  border-radius: 1rem;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  border-radius: 9999px;
  background: #F3F4F6;
  border: none;
  font-size: 1.25rem;
  line-height: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6B7280;
  transition: all 0.2s;
}

.modal-close:hover {
  background: #E5E7EB;
  color: #374151;
}
