.app {
  min-height: 100vh;
  background-color: #f5f6fa;
  display: flex;
  flex-direction: column;
}

.top-nav {
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #2c3e50;
  color: white;
}

.nav-brand {
  margin-right: 2rem;
}

.nav-brand a {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
}

.nav-menu {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-grow: 1;
}

.main-menu,
.admin-menu {
  display: flex;
  gap: 1rem;
}

.menu-item,
.admin-menu-item {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.menu-item:hover,
.admin-menu-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.nav-auth {
  margin-left: auto;
}

.nav-auth a {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.nav-auth a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.header {
  background-color: #0078d4;
  color: white;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  position: relative;
  z-index: 10;
}

.header .logo-title-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.header h1 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 500;
}

.header-actions {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.header-buttons {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.generate-from-file-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  background-color: #00594c;
  color: white;
  transition: background-color 0.2s;
}

.generate-from-file-button:hover {
  background-color: #004a3f;
}

.logout-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  background-color: #757575;
  color: white;
  transition: background-color 0.2s;
}

.logout-button:hover {
  background-color: #616161;
}

.logout-button svg {
  font-size: 16px;
}

.logo {
  height: 40px;
  width: auto;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  z-index: 1;
}

.upload-button {
  background-color: #035a2a;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  z-index: 100;
}

.main-content {
  flex: 1;
  display: flex;
  overflow: hidden;
}

.sidebar {
  width: 250px;
  border-right: 1px solid #e0e0e0;
  overflow-y: auto;
}

.content {
  flex: 1;
  padding: 1rem;
  overflow-y: auto;
}
