.test-case-tree {
  padding: 1rem;
  background: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.test-case-tree ul {
  list-style: none;
  padding-left: 1.5rem;
  margin: 0;
}

.tree-node {
  margin: 0.5rem 0;
}

.node-content {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
}

.node-content[data-type='test_case'] {
  cursor: pointer;
  transition: background-color 0.2s;
}

.node-content[data-type='test_case']:hover {
  background-color: #f5f5f5;
}

.node-content[data-type='test'] {
  cursor: pointer;
  transition: background-color 0.2s;
}

.node-content[data-type='test']:hover {
  background-color: #f5f5f5;
}

.node-content[data-type='root'] {
  font-weight: bold;
  color: #1976d2;
  font-size: 1.1em;
}

.node-content[data-type='group'] {
  font-weight: bold;
  color: #455a64;
}

.tree-node-toggle {
  cursor: pointer;
  user-select: none;
  color: #666;
  width: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.tree-node-label {
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.tree-node-label:hover {
  background-color: #f0f0f0;
}

.tree-node-label.selected {
  background-color: #e3f2fd;
  color: #1976d2;
  font-weight: 500;
}

.tree-node-link {
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
  padding: 4px 8px;
  text-align: left;
  font-size: 14px;
  text-decoration: none;
  display: inline-block;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.tree-node-link:hover {
  background-color: #f0f0f0;
}

.tree-node-link.selected {
  background-color: #e8f0fe;
  color: #1a73e8;
  font-weight: 500;
  position: relative;
}

.tree-node-link.selected::before {
  content: '';
  position: absolute;
  left: -12px;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 100%;
  background-color: #1a73e8;
  border-radius: 0 2px 2px 0;
}

.tree-node-link {
  background: none;
  border: none;
  color: black;
  text-decoration: none;
  cursor: pointer;
  padding: 0;
  font: inherit;
  text-align: left;
  margin-left: 15px; /* Align with group labels */
}

.test-case-buttons {
  display: flex;
  align-items: center;
}

.test-case-buttons .tree-node-link {
  margin-right: 10px;
}

.run-button {
  background-color: #4CAF50; /* Green background */
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  cursor: pointer;
  border-radius: 4px;
}

.run-button:hover {
  background-color: #45a049;
}

.group-label {
  font-weight: bold;
  color: black;
  margin-left: 0;
}

.error-message {
  color: #d32f2f;
  padding: 1rem;
  background-color: #ffebee;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.no-data-message {
  color: #666;
  padding: 1rem;
  text-align: center;
  font-style: italic;
}